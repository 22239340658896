import { HttpContext } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ControlContainer } from '@angular/forms'
import { FormGroup } from '@ngneat/reactive-forms'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'
import { VoucherDetailItem, VouchersAPIService } from '@slovgen-ui/api'
import { OrderFormHeaderModel, OrderFormModel } from '@slovgen-ui/order-components'
import {
    AnimalClass,
    CacheService,
    MessageService,
    NO_DEFAULT_ERR_HANDLER_CONTEXT,
    SelectItem,
} from '@slovgen-ui/shared'
import { Dropdown } from 'primeng/dropdown'
import { Observable, catchError, finalize } from 'rxjs'

@Component({
    selector: 'slovgen-ui-order-completion',
    templateUrl: './order-completion.component.html',
    styles: [
        `
            .form-check-label {
                margin-left: 0.5rem;
            }
        `,
    ],
})
@UntilDestroy()
export class OrderCompletionComponent implements OnInit {
    @Output()
    finish: EventEmitter<any> = new EventEmitter()
    @Output()
    prev: EventEmitter<any> = new EventEmitter()
    @Input()
    form: FormGroup<OrderFormModel>
    headerForm: FormGroup<OrderFormHeaderModel>
    extractionMethodSelectItems: SelectItem<number>[]
    isBirdOrder = false
    language = ''
    voucherLoading: boolean
    vouchers: VoucherDetailItem[] = []
    mailDisabled = false
    vouchers$: Observable<VoucherDetailItem[]>

    constructor(
        public controlContainer: ControlContainer,
        private translate: TranslateService,
        private cache: CacheService,
        private voucherApi: VouchersAPIService,
        private message: MessageService
    ) {
        this.language = this.translate.currentLang.toLowerCase()
        this.vouchers$ = this.voucherApi
            .getVouchers({ validOnly: true }, undefined, false, {
                context: new HttpContext().set(NO_DEFAULT_ERR_HANDLER_CONTEXT, true),
            })
            .pipe(
                catchError(() => {
                    this.message.addErrorMessage('voucher.loadError')
                    return []
                })
            )
    }

    ngOnInit(): void {
        this.headerForm = this.form.controls.orderHeader as FormGroup<OrderFormHeaderModel>
        // const firstSampleCategory = this.form.value.samples[0].

        if (this.headerForm.value.vouchersUsed) {
            this.headerForm.value.vouchersUsed.split(',').forEach((id) => this.addVoucher(id))
        }
        this.extractionMethodSelectItems = [
            { id: -1, label: this.translate.instant('common.no') },
            ...this.cache.extractionMethodSelectItemsForKit.map((i) => ({
                ...i,
                label: this.translate.instant('common.yes') + ' - ' + i.label,
            })),
        ]
        this.isBirdOrder = this.form
            .getRawValue()
            .samples?.some((sfm) => sfm.animal?.rootCategoryId === AnimalClass.BIRD)
        if (this.isBirdOrder) {
            this.headerForm.controls.resultsByMail.setValue(true)
        }
    }

    addVoucher(inputValue: string | VoucherDetailItem, voucherEl: Dropdown = null) {
        let voucherId = ''
        if (!inputValue) {
            return
        }
        if (typeof inputValue === 'string') {
            voucherId = inputValue.trim()
        } else {
            voucherId = inputValue.id
        }
        voucherId = voucherId.trim()
        if (!voucherId) {
            return
        }
        this.voucherLoading = true
        this.voucherApi
            .getVoucherById({ voucherId }, undefined, false, {
                context: new HttpContext().set(NO_DEFAULT_ERR_HANDLER_CONTEXT, true),
            })
            .pipe(finalize(() => (this.voucherLoading = false)))
            .subscribe({
                next: (data) => {
                    if (voucherEl) {
                        voucherEl.clear()
                    }
                    const dupe = this.vouchers.some((v) => v.id === data.id)
                    if (dupe) {
                        this.message.addWarnMessage('voucher.duplicateError')
                        return
                    }
                    if (data.balance === 0) {
                        this.message.addWarnMessage('voucher.usedError')
                        return
                    }
                    if (data.validUntil && new Date(data.validUntil) < new Date()) {
                        this.message.addWarnMessage('voucher.expiredError', {
                            date: new Date(data.validUntil).toLocaleDateString(),
                        })
                        return
                    }
                    this.vouchers.push(data)
                    if (data.type.indexOf('E')) {
                        this.onlyElectronic()
                    }
                    this.headerForm.patchValue({ vouchersUsed: this.vouchers.map((v) => v.id).join() })
                },
                error: (err) => {
                    this.message.addErrorMessage('voucher.loadError')
                },
            })
    }
    onlyElectronic() {
        this.mailDisabled = true
        this.headerForm.patchValue({ resultsByMail: false, resultsByEmail: true })
    }

    deleteVoucher(id: string) {
        this.mailDisabled = false
        this.vouchers.splice(
            this.vouchers.findIndex((v) => v.id === id),
            1
        )
        this.vouchers.forEach((data) => {
            if (data.type.indexOf('E')) {
                this.mailDisabled = true
            }
        })
        this.headerForm.patchValue({ vouchersUsed: this.vouchers.map((v) => v.id).join() })
    }

    back() {
        this.prev.emit(true)
    }

    next() {
        this.finish.emit(true)
    }
}
