import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({ providedIn: 'root' })
export class MessageService {
    constructor(private toastr: ToastrService, private translate: TranslateService) {}
    addSuccessMessage(message: string) {
        return this.addMessage({ severity: 'success', message })
    }
    addErrorMessage(message: string) {
        return this.addMessage({ severity: 'error', message })
    }
    addWarnMessage(message: string, params?: any) {
        return this.addMessage({ severity: 'warn', message }, params)
    }

    addMessage(
        message: {
            severity: 'error' | 'success' | 'info' | 'warn'
            message: string
            detail?: string
            icon?: string
        },
        params?: Object
    ) {
        const severity = message.severity || 'info'
        message.message = this.translate.instant(message.message, params)
        message.detail = message.detail && this.translate.instant(message.detail)
        switch (severity.toLowerCase()) {
            case 'error':
                this.toastr.error(message.detail, message.message)
                break
            case 'success':
                this.toastr.success(message.detail, message.message)
                break
            case 'info':
                this.toastr.info(message.detail, message.message)
                break
            case 'warn':
                this.toastr.warning(message.detail, message.message)
                break
            default:
                this.toastr.show(message.detail, message.message)
                break
        }
    }

    public bigSuccessMessage(message: string) {
        message = this.translate.instant(message)
        var toast = this.toastr.success(undefined, message, {
            closeButton: true,
            disableTimeOut: 'timeOut',
            // positionClass: 'toast-full-screen',
            toastClass: 'ngx-toastr toast-full-screen-message',
        })
        toast.onShown.subscribe(() => {
            document.querySelector('.toast-container')?.classList.toggle('toast-full-screen')
        })
        toast.onHidden.subscribe(() => {
            document.querySelector('.toast-container')?.classList.toggle('toast-full-screen')
        })
    }
}
